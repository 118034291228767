import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Header from 'components/Header';
import FormInfoBlock from 'components/ui/FormInfoBlock';
// import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import './styles.css';
import * as Styled from './styles';
import ContactForm from 'components/ui/ContactForm'

const ConctactInfo = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "contact section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "contact" } } }, sort: { fields: fileAbsolutePath }) {
        edges {
          node {
            id
            frontmatter {
              title
              icon
              content
            }
          }
        }
      }
    }
  `);

  const sectionTitle = markdownRemark.frontmatter;
  const contacts = allMarkdownRemark.edges;

  return (
    <section id="contact-section">
      <Header />
      <div id="inside-contact">
        <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} center />
        <ContactForm />
        
        {contacts.map((item) => {
          const {
            id,
            frontmatter: { title, icon, content }
          } = item.node;

          return (
            <Styled.ContactInfoItem key={id}>
              
              <FormInfoBlock icon={icon} title={title} content={content} center />
            </Styled.ContactInfoItem>
          );
        })}
      </div>
      
      
    </section>
    
  );
};

export default ConctactInfo;
