import React from 'react';
import Header from 'components/Header';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Contact from 'components/Contact';

const ContactPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Contact />
    </Layout>
  );
};

export default ContactPage;
